import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import $ from "jquery";
import Carousel from "../Carousel";
import { FetchService } from "../../service/FetchService";
import configData from "../../config.json";
import Navbar from "../Navbar";
import Footer from "../Footer";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import PageLoader from "../pageLoader/PageLoader";

function Home() {
  const [data, setData] = useState(null);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    $("body").removeClass("overflow-hidden");
    $(".footer-info").removeClass("z-inc");
    var prevScrollPos = window.pageYOffset;
    window.onscroll = function () {
      var currentScrollPos = window.pageYOffset;
      // Scroll down
      if (prevScrollPos < currentScrollPos) {
        $(".header").addClass("header-hidden").removeClass("header-visible");
        $("#tile-1")
          .addClass("header-hidden-m")
          .removeClass("header-visible-m");
        $(".footer-info").removeClass("z-inc");
      }
      // Scroll up
      else {
        var scroll = $(window).scrollTop();
        if (scroll <= 100) {
          $(".header")
            .removeClass("header-hidden")
            .removeClass("header-visible");
          $("#tile-1")
            .removeClass("header-hidden-m")
            .removeClass("header-visible-m");
          $(".footer-info").removeClass("z-inc");
        } else {
          $(".header").removeClass("header-hidden").addClass("header-visible");
          $("#tile-1")
            .removeClass("header-hidden-m")
            .addClass("header-visible-m");
          $(".footer-info").removeClass("z-inc");
        }
      }
      prevScrollPos = currentScrollPos;
    };

    var width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
    if (width <= 768) {
      $("main").click(function () {
        $(".overlay-123").removeClass("d-block");
      });

      $(window).scroll(function () {
        $(".footer-info").removeClass("z-inc");
        if (
          $(window).scrollTop() + $(window).height() >
          $(document).height() - 280
        ) {
          //you are at bottom
          $(".footer-info").addClass("z-inc");
        }
      });

      $(".footer-info .animate-image span").click(function () {
        $(".footer-info").addClass("z-inc");
        $("#btn-back-to-top").addClass("d-none");
        $(".overlay-123").addClass("d-block");
        disableScroll();
      });
    }

    var width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
    if (width >= 768) {
      $(window).scroll(function () {
        $(".footer-info").removeClass("z-inc");
        if (
          $(window).scrollTop() + $(window).height() >
          $(document).height() - 660
        ) {
          //you are at bottom
          $(".footer-info").addClass("z-inc");
          console.log(width);
        }
      });

      $(".animate-image").hover(
        function () {
          $(".footer-info").addClass("z-inc");
          $("#btn-back-to-top").addClass("d-none");
          // $('body').addClass('overflow-hidden');
          $(".overlay-123").addClass("d-block");
          disableScroll();
        },
        function () {
          $("#btn-back-to-top").removeClass("d-none");
          //$('body').removeClass('overflow-hidden');
          $(".overlay-123").removeClass("d-block");
          enableScroll();
        }
      );
    }

    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 200) {
        $(".header").removeClass("bg-none");
        $("#btn-back-to-top").addClass("d-block");
      } else {
        $(".header").addClass("bg-none");
        $("#btn-back-to-top").removeClass("d-block");
      }
    });

    $(".navbar-toggler").click(function () {
      if ($(".navbar-collapse").find(".show")) {
        $("body").toggleClass("scrollremove");
        $(".navbar-brand").toggleClass("logo-invert");
      } else {
        $("body").removeClass("scrollremove");
        $(".navbar-brand").removeClass("logo-invert");
      }
    });

    // $(document).ready(function () {
    //   $('#PMD-Slider').on('slide.bs.carousel', function (e) {
    //     console.log("sddffgd");
    //     var nextSlide = $(e.relatedTarget);
    //     var video = nextSlide.find('video');
    //     if (video.length > 0) {
    //       console.log('active video', video[0])
    //       video[0].currentTime = 0;
    //       video[0].play();
    //     }
    //   });
    // });

    $(document).ready(function () {
      // set time out 5 sec
      setTimeout(function () {
        $(".carousel-indicators .item__2").trigger("click");
      }, 3500);
    });

    // $(document).ready(function () {
    //   $('#PMD-Slider').on('slide.bs.carousel', function (e) {
    //     var nextSlide = $(e.relatedTarget);
    //     var video = nextSlide.find('video');

    //     // $('#PMD-Slider .carousel-item').each(function () {
    //     //   // this.stop();
    //     //   this.currentTime = 0;
    //     // });

    //     if (video.length > 0) {
    //       // video[0].play();

    //       // video.on('ended', function () {
    //       //   $('#PMD-Slider').carousel(0);
    //       // });
    //     }
    //   });

    //   // setTimeout(function () {
    //   //   $('#PMD-Slider').carousel(1);
    //   // }, 3500);
    // });

    $(".overlay-123").click(function () {
      $(".overlay-123").removeClass("d-block");
      setTimeout(function () {
        $(".footer-info").removeClass("z-inc");
      }, 500);
      enableScroll();
    });
    function show_12() {
      $(".hidden-up").addClass("show");
      $("body").addClass("overflow-hidden");
    }

    function hide_12() {
      $(".hidden-up").removeClass("show");
      $("body").removeClass("overflow-hidden");
    }

    // left: 37, up: 38, right: 39, down: 40,
    // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
    var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

    function preventDefault(e) {
      e.preventDefault();
    }

    function preventDefaultForScrollKeys(e) {
      if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
      }
    }

    // modern Chrome requires { passive: false } when adding event
    var supportsPassive = false;
    try {
      window.addEventListener(
        "test",
        null,
        Object.defineProperty({}, "passive", {
          get: function () {
            supportsPassive = true;
          },
        })
      );
    } catch (e) {}

    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent =
      "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

    // call this to Disable
    function disableScroll() {
      window.addEventListener("DOMMouseScroll", preventDefault, false); // older FF
      window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
      window.addEventListener("touchmove", preventDefault, wheelOpt); // mobile
      window.addEventListener("keydown", preventDefaultForScrollKeys, false);
    }

    // call this to Enable
    function enableScroll() {
      window.removeEventListener("DOMMouseScroll", preventDefault, false);
      window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
      window.removeEventListener("touchmove", preventDefault, wheelOpt);
      window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
    }
  });

  useEffect(() => {
    const fetchService = new FetchService();
    const config = {
      method: "get",
      url: configData.SERVER_URL + "product/projectslist?items=200",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token":
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDc0NjEwMTQsImlkIjoiNjViNjBiMjIwYmEzMDQ3NTg4YjA5M2RkIiwiaWF0IjoxNzA3Mzc0NjE0fQ.xR-t4a6cl38I27i-PWoinjzDzjd5ncdkBCwaj2rBl1Y",
      },
    };
    fetchService.getService(config).then((data) => setData(data["result"]));
  }, []);

  useEffect(() => {
    const fetchService = new FetchService();
    const config = {
      method: "get",
      url: configData.SERVER_URL + "banner/list?items=100",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token":
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDc0NjEwMTQsImlkIjoiNjViNjBiMjIwYmEzMDQ3NTg4YjA5M2RkIiwiaWF0IjoxNzA3Mzc0NjE0fQ.xR-t4a6cl38I27i-PWoinjzDzjd5ncdkBCwaj2rBl1Y",
      },
    };
    fetchService.getService(config).then((data) => setBanner(data["result"]));
  }, []);

  const showAllCategory = (item) => {
    const category = item.scope_category;
    let splitArray = category.split(",");

    // Add spaces after each comma
    let formattedString = splitArray.join(", ");

    // Replace the last comma with "and" and remove the space after it
    formattedString = formattedString.replace(/,([^,]*)$/, " and$1");
    return formattedString;
  };

  const sliderRef = useRef(null);
  const videoRefs = useRef([]);

  useEffect(() => {
    const handleSlide = (e) => {
      const previousSlide = e.relatedTarget.previousElementSibling;
      if (previousSlide) {
        const prevVideo = previousSlide.querySelector("video");
        if (prevVideo) {
          console.log("Stopping previous video:", prevVideo);
          prevVideo.pause();
          prevVideo.currentTime = 0;
          // prevVideo.play();
        }
      }
    };

    const handleSlid = (e) => {
      const activeSlide = sliderRef.current.querySelector(
        ".carousel-item.active"
      );
      if (activeSlide) {
        const activeVideo = activeSlide.querySelector("video");
        if (activeVideo) {
          console.log("Starting next video:", activeVideo);
          activeVideo.currentTime = 0;
          activeVideo.play();
        }
      }
    };

    const slider = sliderRef.current;
    slider.addEventListener("slide.bs.carousel", handleSlide);
    slider.addEventListener("slid.bs.carousel", handleSlid);

    // Play the video of the initially active slide
    // const initialSlide = slider.querySelector('.carousel-item.active');
    // if (initialSlide) {
    //   const initialVideo = initialSlide.querySelector('video');
    //   if (initialVideo) {
    //     initialVideo.currentTime = 0;
    //     initialVideo.play();
    //   }
    // }

    // Cleanup the event listeners on component unmount
    return () => {
      slider.removeEventListener("slide.bs.carousel", handleSlide);
      slider.removeEventListener("slid.bs.carousel", handleSlid);
    };
  }, []);

  // useEffect(() => {
  //   // const handleSlide = (e) => {
  //   //   const nextSlide = e.target.querySelector('.carousel-item.active');
  //   //   const video = nextSlide.querySelector('video');
  //   //   if (video) {
  //   //     video.currentTime = 0;
  //   //     video.play();
  //   //   }
  //   // };
  //   const handleSlide = (e) => {
  //     // Reset and stop the video of the previously active slide
  //     const previousSlide = sliderRef.current.querySelector('.carousel-item.active');
  //     if (previousSlide) {
  //       const prevVideo = previousSlide.querySelector('video');
  //       if (prevVideo) {
  //         console.log('prevVideo.currentTime', prevVideo.dataset.src, prevVideo.currentTime)
  //         prevVideo.currentTime = 0;
  //         // prevVideo.pause();
  //       }
  //     }

  //     // Play the video of the newly active slide
  //     const nextSlide = e.relatedTarget;
  //     const nextVideo = nextSlide.querySelector('video');
  //     if (nextVideo) {
  //       console.log('nextVideo.currentTime', nextVideo.dataset.src, nextVideo.currentTime)
  //       nextVideo.currentTime = 0;
  //       // nextVideo.play();
  //     }
  //   };

  //   const slider = sliderRef.current;
  //   slider.addEventListener('slid.bs.carousel', handleSlide);

  //      // Play the video of the initially active slide
  //      const initialSlide = slider.querySelector('.carousel-item.active');
  //      if (initialSlide) {
  //        const initialVideo = initialSlide.querySelector('video');
  //        if (initialVideo) {
  //          initialVideo.currentTime = 0;
  //          initialVideo.play();
  //        }
  //      }

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     slider.removeEventListener('slid.bs.carousel', handleSlide);
  //   };
  // }, []);

  const isVideo = (url) => {
    const videoExtensions = [
      "mp4",
      "webm",
      "ogg",
      "avi",
      "mov",
      "wmv",
      "flv",
      "mkv",
    ];
    const extension = url.split(".").pop().toLowerCase();
    return videoExtensions.includes(extension);
  };

  return (
    <>
      <div className="home" style={{ overflow: "hidden" }}>
        <div className="header bg-none">
          <Navbar isHomePage={true} />
        </div>
        <main className="main-sec">
          {/* home section slider*/}
          <div className="ultra-padding position-fixed sec-1 w-100">
            <div
              id="PMD-Slider"
              className="carousel slide"
              data-bs-ride="carousel"
              data-bs-pause="false"
              ref={sliderRef}
            >
              <div className="carousel-inner">
                <div
                  className="carousel-item item-1 active"
                  data-bs-interval={3100}
                >
                  <video
                    autoPlay
                    muted
                    loop
                    ref={(el) => (videoRefs.current[0] = el)}
                    data-src="./video/Lenskart.mp4"
                  >
                    <source src="./video/Lenskart.mp4" type="video/mp4" />
                  </video>
                  <Link to="/project/lenskart">
                    <div className="carousel-caption text-start">
                      <div className="head">
                        <h3 className="display-6 mb-2 text-white fw-bold">
                          Lenskart's Rebranding Journey Redefines Eyewear Retail
                        </h3>
                      </div>
                      <div className="para">
                        <p className="fs-5 mb-0 text-white">
                          A compelling brand language for Lenskart that
                          resonates with its audience, showcases eyewear
                          innovation, and positions it as an industry leader.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="carousel-item item-2" data-bs-interval={3100}>
                  <video
                    autoPlay
                    muted
                    loop
                    ref={(el) => (videoRefs.current[1] = el)}
                    data-src="./video/Pandemics.mp4"
                  >
                    <source src="./video/Pandemics.mp4" type="video/mp4" />
                  </video>
                  <Link to="/project/isee-assessment-unesco-mgiep">
                    <div className="carousel-caption text-start">
                      <div className="head">
                        <h3 className="display-6 mb-2 text-white fw-bold">
                          Publication created for UNESCO MGIEP in collaboration
                          with the PMO, Government of India
                        </h3>
                      </div>
                      <div className="para">
                        <p className="fs-5 mb-0 text-white">
                          Innovatively presented research findings in a visually
                          engaging format, incorporating creative elements to
                          enhance understanding & accessibility.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="carousel-item item-3" data-bs-interval={3100}>
                  <video
                    autoPlay
                    muted
                    loop
                    ref={(el) => (videoRefs.current[2] = el)}
                    data-src="./video/SVNH.mp4"
                  >
                    <source src="./video/SVNH.mp4" type="video/mp4" />
                  </video>
                  <Link to="/project/shivan-and-narresh">
                    <div className="carousel-caption text-start">
                      <div className="head">
                        <h3 className="display-6 mb-2 text-white fw-bold">
                          India's first luxury holiday brand, worn by numerous
                          celebrities worldwide
                        </h3>
                      </div>
                      <div className="para">
                        <p className="fs-5 mb-0 text-white">
                          Shivan & Narresh, a unique logotype that embodies the
                          brand's ethos of modernity with a classic touch.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="carousel-item item-4" data-bs-interval={3100}>
                  <video
                    autoPlay
                    muted
                    loop
                    ref={(el) => (videoRefs.current[3] = el)}
                    data-src="./video/D01[C]_Video04.mp4"
                  >
                    <source src="./video/D01[C]_Video04.mp4" type="video/mp4" />
                  </video>
                  <Link to="/project/the-great-himalayan-exploration">
                    <div className="carousel-caption text-start">
                      <div className="head">
                        <h3 className="display-6 mb-2 text-white fw-bold">
                          Fostering sustainable tourism initiatives, Royal
                          Enfield x UNESCO
                        </h3>
                      </div>
                      <div className="para">
                        <p className="fs-5 mb-0 text-white">
                          A palette reflective of the nation's heritage,
                          meticulously documented colors, forms, and patterns.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="carousel-item item-5" data-bs-interval={3100}>
                  <video
                    autoPlay
                    muted
                    loop
                    ref={(el) => (videoRefs.current[4] = el)}
                    data-src="./video/AloFrut.mp4"
                  >
                    <source src="./video/AloFrut.mp4" type="video/mp4" />
                  </video>
                  <Link to="/project/alofrut">
                    <div className="carousel-caption text-start">
                      <div className="head">
                        <h3 className="display-6 mb-2 text-white fw-bold">
                          Making healthy living unconventionally conventional
                        </h3>
                      </div>
                      <div className="para">
                        <p className="fs-5 mb-0 text-white">
                          Branding and Packaging design for AloFrut, Juices and
                          Mocktails. India's leading agro based beverages.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="carousel-item item-6" data-bs-interval={3100}>
                  <video
                    autoPlay
                    muted
                    loop
                    ref={(el) => (videoRefs.current[5] = el)}
                    data-src="./video/D01[C].mp4"
                  >
                    <source src="./video/D01[C].mp4" type="video/mp4" />
                  </video>
                  <Link to="/project/panchamrit">
                    <div className="carousel-caption text-start">
                      <div className="head">
                        <h3 className="display-6 mb-2 text-white fw-bold">
                          Panchamrit: bring the benefits of active ayurveda into
                          everyday life
                        </h3>
                      </div>
                      <div className="para">
                        <p className="fs-5 mb-0 text-white">
                          Pancha-Amrit seeks to transcend conventional
                          representations of Ayurveda by embodying the essence
                          of -'Amrit' - the elixir of life - in its products.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="carousel-item item-7" data-bs-interval={3100}>
                  <video
                    autoPlay
                    muted
                    loop
                    ref={(el) => (videoRefs.current[6] = el)}
                    data-src="./video/W_Landing Page.mp4"
                  >
                    <source src="./video/W_Landing Page.mp4" type="video/mp4" />
                  </video>
                  <Link to="/project/white-print-o-pack">
                    <div className="carousel-caption text-start">
                      <div className="head">
                        <h3 className="display-6 mb-2 text-white fw-bold">
                          Packaging Trust, Unpacking
                          Excellence
                        </h3>
                      </div>
                      <div className="para">
                        <p className="fs-5 mb-0 text-white">
                          Committed to setting new benchmarks in ethical
                          business, powered by transparent processes and
                          data-driven decision-making.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="carousel-item item-8" data-bs-interval={3100}>
                  <video
                    autoPlay
                    muted
                    loop
                    ref={(el) => (videoRefs.current[7] = el)}
                    data-src="./video/KS_Landing Page.mp4"
                  >
                    <source
                      src="./video/KS_Landing Page.mp4"
                      type="video/mp4"
                    />
                  </video>
                  <Link to="/project/ksheer-sagar">
                    <div className="carousel-caption text-start">
                      <div className="head">
                        <h3 className="display-6 mb-2 text-white fw-bold">
                          Varanasi's heritage with a modern & clean
                          design
                        </h3>
                      </div>
                      <div className="para">
                        <p className="fs-5 mb-0 text-white">
                          Known for its authentic, traditional sweets made from
                          recipes passed down through generations.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>

                {/* <div className="carousel-item item-2" data-bs-interval={4000}>
                <img
                  src="./img/home-banner-2.webp"
                  className="w-100"
                  alt="Home Banner"
                />
                <div className="carousel-caption text-start">
                  <div className="head">
                    <h3 className="display-6 mb-2 text-white fw-bold">
                      A seamless transition of the design language from the Mocktails
                      to the Juices brand.
              </h3>
                  </div>
                  <div className="para">
                    <p className="fs-5 mb-0 text-white">
                      The brand defines itself with the usage of aloe vera in all of
                      its products, which is their unique selling point.
              </p>
                  </div>
                </div>
              </div>
              <div className="carousel-item item-3" data-bs-interval={4000}>
                <img
                  src="./img/home-banner-3.webp"
                  className="w-100"
                  alt="Home Banner"
                />
                <div className="carousel-caption text-start">
                  <div className="head">
                    <h3 className="display-6 mb-2 text-white fw-bold">
                      A modern vent through the colours &amp; clean crisp design
                      language.
              </h3>
                  </div>
                  <div className="para">
                    <p className="fs-5 mb-0 text-white">
                      The core idea of the brand is to generate fuel from recycling
                      materials and also to work towards the upliftment of Senegal.
              </p>
                  </div>
                </div>
              </div>
               */}
                <div className="carousel-indicators">
                  <button
                    key="0"
                    type="button"
                    data-bs-target="#PMD-Slider"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  />
                  <button
                    key="1"
                    type="button"
                    data-bs-target="#PMD-Slider"
                    data-bs-slide-to="1"
                    className=""
                    aria-label="Slide 2"
                  />
                  <button
                    key="2"
                    type="button"
                    data-bs-target="#PMD-Slider"
                    data-bs-slide-to="2"
                    className=""
                    aria-label="Slide 3"
                  />
                  <button
                    key="3"
                    type="button"
                    data-bs-target="#PMD-Slider"
                    data-bs-slide-to="3"
                    className=""
                    aria-label="Slide 4"
                  />
                  <button
                    key="4"
                    type="button"
                    data-bs-target="#PMD-Slider"
                    data-bs-slide-to="4"
                    className=""
                    aria-label="Slide 5"
                  />
                  <button
                    key="5"
                    type="button"
                    data-bs-target="#PMD-Slider"
                    data-bs-slide-to="5"
                    className=""
                    aria-label="Slide 6"
                  />
                  <button
                    key="6"
                    type="button"
                    data-bs-target="#PMD-Slider"
                    data-bs-slide-to="6"
                    className=""
                    aria-label="Slide 7"
                  />
                  <button
                    key="7"
                    type="button"
                    data-bs-target="#PMD-Slider"
                    data-bs-slide-to="7"
                    className=""
                    aria-label="Slide 8"
                  />
                </div>
              </div>
              <div className="Logo-area px-0 d-none d-lg-block">
                <div className="container ps-0">
                  <Link
                    to="/"
                    onClick={(event) => {
                      event.preventDefault();
                      window.location.href = "/";
                    }}
                    className="logo-img"
                  >
                    <img
                      src="./img/pmd-logo-black.svg"
                      alt=""
                      width="auto"
                      height="48px"
                      className=""
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="ultra-padding">
            <div className="bg-white position-relative sec-2">
              {/* start 2nd section */}
              <section className="section-2 pv-top-60 mb-md-4 px-lg-0">
                <div className="container px-1">
                  <div className="row">
                    {data &&
                      data.slice(0, 2).map((item, index) => (
                        <div key={index} className="col-md-6 pr-20">
                          <Link
                            className="link-fs-22"
                            to={{
                              pathname: `/project/${item.productName
                                .toLowerCase()
                                .replace(/[^a-z0-9-]/g, "-")
                                .replace(/-+/g, "-")
                                .replace(/^-|-$/g, "")}`,
                              state: { data: item._id },
                            }}
                          >
                            <div className="card border-0 ">
                              <div className="img-box">
                                {/* <img src={configData.API_BASE_URL + '/' + item.images} alt="" /> */}
                                {isVideo(item.images) ? (
                                  <video
                                    loop
                                    autoPlay
                                    muted
                                    className="img-fluid"
                                  >
                                    <source
                                      src={
                                        configData.API_BASE_URL +
                                        "/" +
                                        item.images
                                      }
                                      type={`video/${item.images
                                        .split(".")
                                        .pop()}`}
                                    />
                                  </video>
                                ) : (
                                  <img
                                    src={
                                      configData.API_BASE_URL +
                                      "/" +
                                      item.images
                                    }
                                    alt="Media content"
                                    className="img-fluid"
                                  />
                                )}
                              </div>
                              <div className="card-body px-0 pt-10">
                                <h3 className="mb-0 color-black fs-22">
                                  {item.productName}
                                </h3>
                                <p className="fs-6 lh-scope mt-1 ibmsans-regular mb-0 color-black">
                                  {showAllCategory(item)}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                  </div>
                </div>
              </section>
              {/* end 2nd section */}
              {/* start 3rd section  */}
              <section className="section-3 mb-5 px-lg-0">
                <div className="container px-1">
                  <div className="row">
                    {data &&
                      data.slice(2, 3).map((item, index) => (
                        <div key={index} className="col-md-12">
                          <Link
                            className="link-fs-22"
                            to={{
                              pathname: `/project/${item.productName
                                .toLowerCase()
                                .replace(/[^a-z0-9-]/g, "-")
                                .replace(/-+/g, "-")
                                .replace(/^-|-$/g, "")}`,
                              state: { data: item._id },
                            }}
                          >
                            <div className="card border-0">
                              <div className="img-box">
                                {/* <img src={configData.API_BASE_URL + '/' + item.images} alt="" /> */}
                                {isVideo(item.images) ? (
                                  <video
                                    loop
                                    autoPlay
                                    muted
                                    className="img-fluid"
                                  >
                                    <source
                                      src={
                                        configData.API_BASE_URL +
                                        "/" +
                                        item.images
                                      }
                                      type={`video/${item.images
                                        .split(".")
                                        .pop()}`}
                                    />
                                  </video>
                                ) : (
                                  <img
                                    src={
                                      configData.API_BASE_URL +
                                      "/" +
                                      item.images
                                    }
                                    alt="Media content"
                                    className="img-fluid"
                                  />
                                )}
                              </div>
                              <div className="card-body px-0 pt-10">
                                <h3 className="mb-0 color-black fs-22">
                                  {item.productName}
                                </h3>
                                <p className="fs-6 lh-scope mt-1 ibmsans-regular mb-0 color-black">
                                  {showAllCategory(item)}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                  </div>
                </div>
              </section>
              {/* end 3rd section  */}
              {/* section 4 start */}
              <section className="section-4 pv-bottom-60 px-lg-0">
                <div className="container px-1">
                  <div className="row">
                    {data &&
                      data.slice(3, 19).map((item, index) => (
                        <div key={index} className="col-md-3 mb-4">
                          <Link
                            className="link-fs-22"
                            to={{
                              pathname: `/project/${item.productName
                                .toLowerCase()
                                .replace(/[^a-z0-9-]/g, "-")
                                .replace(/-+/g, "-")
                                .replace(/^-|-$/g, "")}`,
                              state: { data: item._id },
                            }}
                          >
                            <div className="card border-0">
                              <div className="img-box">
                                {/* <img src={configData.API_BASE_URL + '/' + item.images} className="img-fluid" /> */}
                                {isVideo(item.images) ? (
                                  <video
                                    loop
                                    autoPlay
                                    muted
                                    className="img-fluid"
                                  >
                                    <source
                                      src={
                                        configData.API_BASE_URL +
                                        "/" +
                                        item.images
                                      }
                                      type={`video/${item.images
                                        .split(".")
                                        .pop()}`}
                                    />
                                  </video>
                                ) : (
                                  <img
                                    src={
                                      configData.API_BASE_URL +
                                      "/" +
                                      item.images
                                    }
                                    alt="Media content"
                                    className="img-fluid"
                                  />
                                )}
                              </div>
                              <div className="card-body px-0 pt-10">
                                <h3 className="mb-0 color-black fs-22">
                                  {item.productName}
                                </h3>
                                <p className="fs-6 lh-scope mt-1 ibmsans-regular mb-0 color-black">
                                  {showAllCategory(item)}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                  </div>
                </div>
                <p className="mb-0 text-center text-uppercase pt-3">
                  <Link
                    to="/project"
                    className="fs-5 color-gray hov-color-black"
                  >
                    View all Projects
                  </Link>
                </p>
              </section>
            </div>
          </div>
          {/* section 4 end */}
          <section
            className="testimonial pv-60"
            style={{ background: "#292929" }}
          >
            <div className="container ">
              <div
                id="testimonial_slider"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner quote-icon">
                  <div className="carousel-item active" data-bs-interval={3100}>
                    <div className="row">
                      <div className="col-md-4 d-flex mt-5 mt-sm-5 mt-md-0 order-md-first order-sm-last">
                        <div className="image-area">
                          <img
                            src="img/piyush-image.png"
                            width="61px"
                            height="auto"
                          />
                        </div>
                        <div className="testimonial-from ms-3">
                          <h6 className="text-white ibm-sans fw-medium">
                            Peyush Bansal
                          </h6>
                          <p className="designation mb-3 fst-italic">
                            Co-Founder, Chief Executive &amp; People Officer
                          </p>
                          <p className="company mb-0 text-white ">
                            Lenskart.com
                          </p>
                        </div>
                      </div>
                      <div className="col-md-7 offset-md-1 ms-0 mt-sm-3 mt-md-0">
                        <p
                          className="text-white fs-5 lh-28 position-relative mb-0 ibm-sans"
                          style={{ zIndex: 0 }}
                        >
                          I am incredibly impressed with the outstanding work
                          that PMD India has done for Lenskart. Their innovative
                          approach to designing our brand language has been
                          instrumental in shaping Lenskart's identity and
                          customer experience.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item" data-bs-interval={3100}>
                    <div className="row">
                      <div className="col-md-4 d-flex mt-5 mt-sm-5 mt-md-0 order-md-first order-sm-last">
                        <div className="image-area">
                          <img
                            src="img/shikha.jpg"
                            width="61px"
                            height="auto"
                          />
                        </div>
                        <div className="testimonial-from ms-3">
                          <h6 className="text-white ibm-sans fw-medium">
                            Shikha Pahwa Verma
                          </h6>
                          <p className="designation mb-3 fst-italic">Founder</p>
                          <p className="company mb-0 text-white ">HouseThis</p>
                        </div>
                      </div>
                      <div className="col-md-7 offset-md-1 ms-0 mt-sm-3 mt-md-0">
                        <p
                          className="text-white fs-5 lh-28 position-relative mb-0 ibm-sans"
                          style={{ zIndex: 0 }}
                        >
                          Our association with PMD has been built over many
                          years, owing to not just their exceptional design
                          acumen but also their ethos of cultivating
                          partnerships for the long term. Prasun and his team
                          have been true allies in our journey right from
                          inception- from logo design to communication campaigns
                          and to brand extensions. I can say with utmost
                          conviction, that they would add tremendous value to
                          any and all brand building efforts.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex testimonial-arrow justify-content-between mt-4">
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#testimonial_slider"
                    data-bs-slide="prev"
                  >
                    <i className="fas fa-arrow-left" />
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#testimonial_slider"
                    data-bs-slide="next"
                  >
                    <i className="fas fa-arrow-right" />
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
}

export default Home;
